<template>
	<!-- begin::page-container-->
	<main class="page-container" id="sme-edu-lectures">
		<!-- begin::edu-banner -->
		<div class="edu-banner">
			<div class="banner-content">
				<div class="page-component">
					<h2 class="banner-title">온라인 진출 배움터<br />비회원 맛보기 강좌</h2>
					<br />
					<br />
					<br />
					<h3 style="color: #0f518e">교육사업 신청 후 교육플랫폼에서 더 많은 디지털 콘텐츠를 확인해보세요!</h3>
				</div>
			</div>
			<div class="banner-image">
				<img v-if="isMobile" src="../../../assets/mobile/img/edu/bg_intro_01.png" alt="비회원 맛보기 강좌 배너" />
				<img v-else src="../../../assets/img/edu/bg_intro_01.png" alt="비회원 맛보기 강좌 배너" />
			</div>
		</div>
		<!-- end::edu-banner -->
		<!-- begin::edu-contents -->
		<div class="edu-contents">
			<!-- begin::edu-contents 1 -->
			<div class="lecture-list-container page-component">
				<div v-if="isMobile" style="margin-top: 15px">
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 24년 교육</h4>
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev1"><i class="icon-arrow"></i></button>
								<button class="btn btn-next1"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions5">
								<swiper-slide v-for="(item, idx) in refineItems1" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<button class="thumbnail-link" @click="clickPreview(item.courseId)">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</button>
										</div>
										<div class="edu-contents" style="margin-top: 0.2rem; min-height: 0">
											<h5 class="tags" style="font-size: 0.85rem">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
						<!-- end::post-card-list -->
					</article>
					<!-- end::edu-contents 1 -->
					<!-- begin::edu-contents 2 -->
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 23년 교육</h4>
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev2"><i class="icon-arrow"></i></button>
								<button class="btn btn-next2"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions6">
								<swiper-slide v-for="(item, idx) in refineItems2" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<button class="thumbnail-link" @click="clickPreview(item.courseId)">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</button>
										</div>
										<div class="edu-contents" style="margin-top: 0.2rem; min-height: 0">
											<h5 class="tags" style="font-size: 0.85rem">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</article>
					<!-- end::post-card-list -->
					<!-- end::edu-contents 2 -->
					<!-- begin::edu-contents 3 -->
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 교육생 인터뷰 및 수기</h4>
							<!--              <h4 class="title">◈ 교육생 <span class="subtext">인터뷰 및 수기</span> </h4>-->
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev3"><i class="icon-arrow"></i></button>
								<button class="btn btn-next3"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions7">
								<swiper-slide v-for="(item, idx) in refineItems3" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<button class="thumbnail-link" @click="clickPreview(item.courseId)">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</button>
										</div>
										<div class="edu-contents" style="margin-top: 0.2rem; min-height: 0">
											<h5 class="tags" style="font-size: 0.85rem">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</article>

					<!-- end::post-card-list -->
					<!-- end::edu-contents 3 -->
					<!-- begin::edu-contents 4 -->
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 소상공인 디지털전환 콘텐츠</h4>
							<!--              <h4 class="title">◈ 21년 소상공인 <span class="subtext">인플루언서 교육</span> </h4>-->
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev4"><i class="icon-arrow"></i></button>
								<button class="btn btn-next4"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions8">
								<swiper-slide v-for="(item, idx) in refineItems4" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<button class="thumbnail-link" @click="clickPreview(item.courseId)">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</button>
										</div>
										<div class="edu-contents" style="margin-top: 0.2rem; min-height: 0">
											<h5 class="tags" style="font-size: 0.85rem">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</article>
					<!-- end::edu-contents 4 -->
				</div>
				<div v-else>
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 24년 교육</h4>
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<!--              <button class="btn btn-prev1" @click="slideButton(true)"><i class="icon-arrow"></i></button>-->
								<!--              <button class="btn btn-next1" @click="slideButton(false)"><i class="icon-arrow"></i></button>-->
								<button class="btn btn-prev1"><i class="icon-arrow"></i></button>
								<button class="btn btn-next1"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions1">
								<swiper-slide v-for="(item, idx) in refineItems1" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<a class="thumbnail-link">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</a>
										</div>
										<div class="edu-contents" style="min-height: 0">
											<h5 class="tags">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
						<!-- end::post-card-list -->
					</article>
					<!-- end::edu-contents 1 -->
					<!-- begin::edu-contents 2 -->
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 23년 교육</h4>
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev2"><i class="icon-arrow"></i></button>
								<button class="btn btn-next2"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions2">
								<swiper-slide v-for="(item, idx) in refineItems2" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<a href="" class="thumbnail-link">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</a>
										</div>
										<div class="edu-contents" style="min-height: 0">
											<h5 class="tags">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</article>
					<!-- end::post-card-list -->
					<!-- end::edu-contents 2 -->
					<!-- begin::edu-contents 3 -->
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 교육생 인터뷰 및 수기</h4>
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev3"><i class="icon-arrow"></i></button>
								<button class="btn btn-next3"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions3">
								<swiper-slide v-for="(item, idx) in refineItems3" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<a href="" class="thumbnail-link">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</a>
										</div>
										<div class="edu-contents" style="min-height: 0">
											<h5 class="tags">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</article>
					<!-- end::post-card-list -->
					<!-- end::edu-contents 3 -->
					<!-- begin::edu-contents 4 -->
					<article class="lecture-article">
						<header class="article-header">
							<h4 class="title">◈ 소상공인 디지털전환 콘텐츠</h4>
						</header>
						<!-- begin::edu-card-list -->
						<div class="edu-swiper-container swiper-container">
							<div class="edu-swiper-buttons swiper-buttons">
								<button class="btn btn-prev4"><i class="icon-arrow"></i></button>
								<button class="btn btn-next4"><i class="icon-arrow"></i></button>
							</div>
							<swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions4">
								<swiper-slide v-for="(item, idx) in refineItems4" :key="idx" class="edu-swiper-wrapper swiper-wrapper">
									<div class="edu-box edu-slide-item swiper-slide">
										<div class="edu-thumbnail">
											<a href="" class="thumbnail-link">
												<img :src="`https://d24x8kvt0el7da.cloudfront.net/${item.courseImg}`" alt="" />
											</a>
										</div>
										<div class="edu-contents" style="min-height: 0">
											<h5 class="tags">{{ item.courseNm }}</h5>
											<p class="description">{{ item.categoryNm1 }} | {{ item.courseType }}</p>
										</div>
										<div class="edu-actions">
											<button v-if="item.previewYn === 'Y'" class="btn btn-outline-white" @click="clickPreview(item.courseId)">
												<span class="text">강의맛보기</span>
											</button>
											<!--                    <a @click="goEducation" class="btn btn-primary" target="_blank">-->
											<!--                      <span class="text">교육받기</span>-->
											<!--                    </a>-->
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</article>
					<!-- end::edu-contents 4 -->
				</div>
			</div>
			<!-- edu-section:edu-section-text -->

			<!--      공지사항 임시주석-->
			<h4 class="education_notice" style="background-color: white; padding-top: 15px; padding-bottom: 20px; text-align: center"><b style="font-weight: 1000">&lt; "알려드려요" - 교육 게시판 &gt;</b></h4>
			<div v-if="!isMobile" class="notice_body-list" style="padding-left: 20%; padding-right: 20%; background-color: white; padding-bottom: 1%">
				<notice-card :items="refineItems" :is-tit="true" />
			</div>
			<div v-else class="notice_body-list" style="padding-left: 3%; padding-right: 3%; background-color: white; padding-top: 5%">
				<notice-card :items="refineItems" :is-tit="true" />
			</div>

			<!-- 한국중소벤처기업유통원 요청으로 삭제처리 -->
			<!--
      <section class="edu-section edu-section-text" style="background-color: #f3f5f7;">
        <div class="section-container page-component" style="display: flex;flex-direction: column;align-items: center;">
          <header class="section-header" style="margin-top: 30px;">
            <h3 class="title" style="word-break: keep-all">'22년 온라인 진출 배움터의 소상공인 인플루언서<br/>교육을 듣고 싶으시다면 두 가지만 해주시면 됩니다.</h3>
          </header>
          <div class="text-container" style="padding: 5px;display: flex;flex-direction: column;background-color:#F3F5F7;border-radius: 5px;margin: 0 15px 0 15px;">
            <p class="text" style="margin-top: 0"><a href="https://portal.valuebuy.kr/signup" class="text-link">가치삽시다 회원가입하기</a><br/>(이미 가입되어 있다면 바로 교육신청 가능)</p>
          </div>
          <div v-if="isMobile" style="display: flex;flex-direction: row;justify-content: space-around;min-width: 350px;">
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3" class="btn btn-primary">
                <span class="text" style="margin-top: 0">기본과정 교육신청</span>
              </a>
            </div>
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3" class="btn btn-primary">
                <span class="text" style="margin-top: 0">심화과정 교육신청</span>
              </a>
            </div>
          </div>
          <div v-else style="display: flex;flex-direction: row;justify-content: space-around;min-width: 350px;width: 680px;margin-bottom: 30px">
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job150&menuIndex=3" class="btn btn-primary" style="width: 250px">
                <span class="text" style="margin-top: 0">기본과정 교육신청</span>
              </a>
            </div>
            <div class="btn-container">
              <a href="https://fanfandaero.kr/portal/readGuidanceJob.do?sportBsnsNo=job164&menuIndex=3" class="btn btn-primary" style="width: 250px">
                <span class="text" style="margin-top: 0">심화과정 교육신청</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      -->
			<!-- //edu-section:edu-section-banner -->
		</div>
		<!-- end::edu-contents -->
	</main>
	<!-- end::page-container(#비회원 맛보기 강의) -->
</template>

<script>
import { ACT_GET_COMMON_CODE_LIST, ACT_GET_EDU_COURSE_LIST, ACT_GET_NOTICE2_LIST, ACT_POST_EDU_SSO } from '../../../store/_act_consts';
import { getItems, getPaging, isSuccess, lengthCheck, setPaging, tryResCallback } from '../../../assets/js/utils';
import { mapGetters } from 'vuex';
import store from '../../../store';
import { MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import router from '../../../router';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { swiperOptions } from '../../../assets/js/swiper.config';
import NoticeList from '../../../components/common/NoticeList';
import NoticeCard from '../../../components/common/NoticeCard';
import VueRouter from 'vue-router';

export default {
	name: 'Education',
	components: {
		Swiper,
		SwiperSlide,
		NoticeList,
		NoticeCard,
	},
	data: () => ({
		isLoading: false,
		// currentIdx: 0,
		hasMore: false,
		swiperOptions1: swiperOptions({
			navigation: {
				nextEl: '.btn-next1',
				prevEl: '.btn-prev1',
			},
			slidesPerView: 4,
			autoplay: false,
			centeredSlides: false,
			loop: false,
		}),
		swiperOptions2: swiperOptions({
			navigation: {
				nextEl: '.btn-next2',
				prevEl: '.btn-prev2',
			},
			slidesPerView: 4,
			autoplay: false,
			centeredSlides: false,
			loop: false,
		}),
		swiperOptions3: swiperOptions({
			navigation: {
				nextEl: '.btn-next3',
				prevEl: '.btn-prev3',
			},
			slidesPerView: 4,
			autoplay: false,
			centeredSlides: false,
			loop: false,
		}),
		swiperOptions4: swiperOptions({
			navigation: {
				nextEl: '.btn-next4',
				prevEl: '.btn-prev4',
			},
			slidesPerView: 4,
			autoplay: false,
			centeredSlides: false,
			loop: false,
		}),
		swiperOptions5: swiperOptions({
			navigation: {
				nextEl: '.btn-next1',
				prevEl: '.btn-prev1',
			},
			slidesPerView: 2,
			autoplay: false,
			centeredSlides: false,
			loop: false,
			spaceBetween: 5,
		}),
		swiperOptions6: swiperOptions({
			navigation: {
				nextEl: '.btn-next2',
				prevEl: '.btn-prev2',
			},
			slidesPerView: 2,
			autoplay: false,
			centeredSlides: false,
			loop: false,
			spaceBetween: 5,
		}),
		swiperOptions7: swiperOptions({
			navigation: {
				nextEl: '.btn-next3',
				prevEl: '.btn-prev3',
			},
			slidesPerView: 2,
			autoplay: false,
			centeredSlides: false,
			loop: false,
			spaceBetween: 5,
		}),
		swiperOptions8: swiperOptions({
			navigation: {
				nextEl: '.btn-next4',
				prevEl: '.btn-prev4',
			},
			slidesPerView: 2,
			autoplay: false,
			centeredSlides: false,
			loop: false,
			spaceBetween: 5,
		}),
		isSlideReady: true,
		totalCount: 0,
		pageSize: 500,
		pageNo: 1,
		items: [],
		noticeItems: [],
		notice: false,
		courseTypeDcds: [
			{ courseTypeDcd: '1012001', courseType: '이러닝' },
			{ courseTypeDcd: '1012002', courseType: '전화어학' },
			{ courseTypeDcd: '1012003', courseType: '집합교육' },
			{ courseTypeDcd: '1012004', courseType: '북러닝' },
			{ courseTypeDcd: '1012005', courseType: '소셜' },
			{ courseTypeDcd: '1012006', courseType: '마이크로러닝' },
			{ courseTypeDcd: '1012007', courseType: '블렌디드' },
		],
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		refineItems() {
			const totalCount = this.noticeItems.length;
			return this.noticeItems.map((x) => ({
				ntcId: x.ntcId,
				ntcTit: x.ntcTit,
				atchFileNm: x.atchFileNm,
				atchFilePtUrl: x.atchFilePtUrl,
				fileExt: x.fileExt,
				regDt: new Date(x.regDt),
				pageNo: 1,
				totalCount: totalCount,
				upporFixYn: x.upporFixYn,
				viewLink: `/notice/${x.ntcId}/view?ctgrCode=1020007`,
			}));
		},

		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
		// slideStyle(){
		//   return {transform: `translate3d(${this.currentIdx*-290}px, 0px, 0px)`, 'transition-duration': `500ms`};
		// },
		refineItems1() {
			return this.items
				.map((x) => ({
					courseId: x.courseId,
					courseNm: x.courseNm,
					categoryId1: x.categoryId1,
					courseTypeDcd: x.courseTypeDcd,
					courseType: ((item) => {
						const courseFilter = this.courseTypeDcds.filter((f) => item.courseTypeDcd === f.courseTypeDcd);
						if (courseFilter.length > 0) return courseFilter[0].courseType;
						return '';
					})(x),
					categoryNm1: x.categoryNm1,
					courseImg: x.courseImg,
					previewYn: x.previewYn,
				}))
				.filter((item) => item.categoryId1 === 14027);
		},
		refineItems2() {
			return this.items
				.map((x) => ({
					courseId: x.courseId,
					courseNm: x.courseNm,
					categoryId1: x.categoryId1,
					courseTypeDcd: x.courseTypeDcd,
					courseType: ((item) => {
						const courseFilter = this.courseTypeDcds.filter((f) => item.courseTypeDcd === f.courseTypeDcd);
						if (courseFilter.length > 0) return courseFilter[0].courseType;
						return '';
					})(x),
					categoryNm1: x.categoryNm1,
					courseImg: x.courseImg,
					previewYn: x.previewYn,
				}))
				.filter((item) => item.categoryId1 === 14028);
		},
		refineItems3() {
			return this.items
				.map((x) => ({
					courseId: x.courseId,
					courseNm: x.courseNm,
					categoryId1: x.categoryId1,
					courseTypeDcd: x.courseTypeDcd,
					courseType: ((item) => {
						const courseFilter = this.courseTypeDcds.filter((f) => item.courseTypeDcd === f.courseTypeDcd);
						if (courseFilter.length > 0) return courseFilter[0].courseType;
						return '';
					})(x),
					categoryNm1: x.categoryNm1,
					courseImg: x.courseImg,
					previewYn: x.previewYn,
				}))
				.filter((item) => item.categoryId1 === 14004);
		},
		refineItems4() {
			return this.items
				.map((x) => ({
					courseId: x.courseId,
					courseNm: x.courseNm,
					categoryId1: x.categoryId1,
					courseTypeDcd: x.courseTypeDcd,
					courseType: ((item) => {
						const courseFilter = this.courseTypeDcds.filter((f) => item.courseTypeDcd === f.courseTypeDcd);
						if (courseFilter.length > 0) return courseFilter[0].courseType;
						return '';
					})(x),
					categoryNm1: x.categoryNm1,
					courseImg: x.courseImg,
					previewYn: x.previewYn,
				}))
				.filter((item) => item.categoryId1 === 14029);
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.name === 'NoticeView') {
				vm.notice = true;
			}
		});
	},
	created() {
		this.getCourse(true);
		// 공지사항 임시주석
		this.getNoticeList();
	},
	updated() {
		if (this.notice) this.scrollToElement();
	},
	methods: {
		getCourse(isInit) {
			if (isInit) {
				this.items = [];
				this.isLoading = true;
			}

			this.$store
				.dispatch(`education/${ACT_GET_EDU_COURSE_LIST}`, {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = this.items.concat(getItems(res));
						setPaging(this, res);
					} else {
						this.hasMore = false;
					}
				})
				.catch((e) => {
					this.hasMore = false;
					console.error(e);
				});
		},
		getNoticeList() {
			if (this.startYmd) this.startYmd.setDate(this.startYmd.getDate() + 1);
			if (this.endYmd) this.endYmd.setDate(this.endYmd.getDate() + 2);

			this.$store
				.dispatch(`notice2/${ACT_GET_NOTICE2_LIST}`, {
					ntcCtgrDcd: '1020007',
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.noticeItems = this.items.concat(getItems(res));
					}
					this.isLoading = false;
				})
				.catch((e) => {
					console.error(e);
					this.isLoading = false;
				});
		},
		// morePage(){
		//   if(this.hasMore) this.pageNo++;
		//   this.getCourse();
		// },
		clickPreview(courseId) {
			console.log('?????');
			window.open(`https://kodma.getsmart.co.kr/course/${courseId}/player/preview`, '새창', 'width=800, height=600, toolbar=no, menubar=no, scrollbars=no, resizable=yes, encrypted-media');
		},
		scrollToElement() {
			const el = this.$el.getElementsByClassName('education_notice')[0];

			if (el) {
				el.scrollIntoView();
			}
		},
		// slideButton(isNext){
		//   if(isNext){
		//     const changeImg = this.currentIdx + 1;
		//     if(changeImg > (this.refineItems1.length - 1)){
		//       this.currentIdx = 0;
		//     }else{
		//       this.currentIdx = changeImg;
		//     }
		//   }else{
		//     const changeImg = this.currentIdx -1;
		//     if(changeImg < 0){
		//       this.currentIdx = (this.refineItems1.length - 1);
		//     }else{
		//       this.currentIdx = changeImg;
		//     }
		//   }
		// }
		// goEducation(){
		//   this.$store.dispatch(`education/${ACT_POST_EDU_SSO}`).then(res=>{
		//     if(isSuccess(res)){
		//       if(res.encId){
		//         location.href = `https://kodma.getsmart.co.kr/client/sso?memberId=${res.encId}`;
		//       }
		//     }else{
		//       store.commit(`common/${MUT_SHOW_ALERT}`, {
		//         title: `로그인이 필요합니다.`,
		//         yesfunc: () => {router.push({name:'Login'});},
		//       });
		//     }
		//   });
		// }
	},
};
</script>
